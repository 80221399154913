/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../style/App.css';
import { rootReducer } from '../reducers';
import actions from '../connector';

const {
  slice: {
    actions: {
      setUserInfo,
    },
  },
  selectUserInfo
} = rootReducer;

const { Title } = Typography;
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  }
}

const items = [
  getItem(<Link to="/app-react">react app sub01</Link>, '1', <UploadOutlined />),
  getItem(<Link to="/app-react2">react app sub02</Link>, '2', <UserOutlined />),
]

const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  const getToken = () => {
    return Math.random().toString(36).slice(-8);
  }
  useEffect(() => {
    dispatch(setUserInfo({ user: '陈先生', sex: '男', token: getToken() }));
  }, [])

  // actions.setGlobalState(userInfo);
  // actions.mainData = userInfo;

  useEffect(() => {
    console.log(userInfo, 'userInfo');
    actions.setGlobalState(userInfo);
    actions.mainData = userInfo;
  }, [userInfo]);

  return (
    <>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className="logo" />
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
        </Sider>
        <Layout>
          <Header style={{ background: colorBgContainer }}>
            <Title level={5} style={{ margin: '1rem 0rem' }}>微前端架构</Title>
          </Header>
          <Content style={{ margin: '0rem' }}>
            {/* 子应用容器 */}
            <div id="container"></div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer>
        </Layout>
      </Layout >
    </>
  );
};

export default App;