import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import { registerMicroApps, start, setDefaultMountApp } from 'qiankun';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from "react-redux"
import { createStore, applyMiddleware } from 'redux'
import { reducer, saga } from "./reducers"
import createSagaMiddleware from "redux-saga";
import actions from '../src/connector';

const newSaga = createSagaMiddleware()  //创建saga中间件

const store = createStore(reducer, applyMiddleware(newSaga)); //创建sotre

newSaga.run(saga) //使用中间件的run方法将自己手动创建的saga挂中间件上去

const container = document.getElementById('main-root');
const root = createRoot(container);

// <React.StrictMode> 严格模式 浏览器会报错 注释掉
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

console.log(process, 'process');
registerMicroApps([
  {
    name: 'sub01',
    entry: process.env.REACT_APP_SUB_REACT,
    container: '#container', //主应用渲染的DIV
    activeRule: '/app-react', //子应用匹配的路由 必须一致
    props: {
      actions
    }
  },
  {
    name: 'sub02',
    entry: process.env.REACT_APP_SUB_REACT2,
    container: '#container', //主应用渲染的DIV
    activeRule: '/app-react2', //子应用匹配的路由 必须一致
    props: {
      actions
    }
  }
  // {
  //   name: 'sub02',
  //   // entry: process.env.APP_SUB_REACT2, //子应用的端口
  //   // entry: '//localhost:5000/subapp/app-react2/',
  //   entry: '/subapp/app-react2/',
  //   // entry: '//wei1.y3iu.com/subapp/app-react2/',
  //   container: '#container', //主应用渲染的DIV
  //   activeRule: '/app-react-router2', //子应用匹配的路由 必须一致
  //   props: {
  //     actions
  //   }
  // },
], {
  beforeLoad: app => {
    console.log('before load app.name====>>>>>', app.name)
  },
  beforeMount: [
    app => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
    }
  ],
  afterMount: [
    app => {
      console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
    }
  ],
  afterUnmount: [
    app => {
      console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
    }
  ]
});

setDefaultMountApp('/app-react');
// 启动 qiankun
start();

reportWebVitals();
