/**
 * qiankun 主应用与微应用通信
 */
import { initGlobalState } from "qiankun"

const state = { user: '', msg: '' }

//初始化通信池
const actions = initGlobalState(state)
//监听通信池变化
actions.onGlobalStateChange((state, pre) => {
  console.log('-----通信池变化响应-----', state, pre);
}, true)

actions.setGlobalState(state);

//不要关闭 不然通信不了
// action.offGlobalStateChange();

export default actions;
