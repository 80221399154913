import { ROOT_KEY } from '../keys';

export const selectContent = (state) => state[ROOT_KEY].content || '1';

export const selectNewsData = (state) => state[ROOT_KEY].newsData || [];

export const selectEggData = (state) => state[ROOT_KEY].eggData || [];

export const selectLoginState = (state) => state[ROOT_KEY].loginState;

export const selectUserInfo = (state) => state[ROOT_KEY].userInfo;
