
import { takeLeading, put, call } from 'redux-saga/effects';
import { slice } from './slice';
import httpGet from '../../unit/http';

const { getNews, setNews, getEggData } = slice.actions;

// console.log(takeLeading(getNews, startGetNews))

export function* startGetNews() {
  try {
    const url = 'https://v.api.aa1.cn/api/api-tplist/go.php/api/News/local_news';
    const param = {
      name: '广东省_广州市',
      page: 0
    }

    const result = yield call(httpGet, {
      url,
      param,
    });

    if (result?.data && result?.status === 200) {
      yield put(setNews(result?.data));
    }
    // yield put(setNews(result?.data));
  } catch (error) {
    console.log(error, '接到')
  }
}

export function* startGetEggData(action) {
  try {
    const url = 'https://api.y3iu.com/company/1';

    const result = yield call(httpGet, {
      url
    });

    console.log(result);

    // if (result?.data && result?.status === 200) {
    //   yield put(setNews(result?.data));
    // }
    // yield put(setNews(result?.data));
  } catch (error) {
    console.log(error, '接到')
  }
}
// console.log(takeLeading(getNews, startGetNews))
export const saga = [
  takeLeading(getNews, startGetNews),
  takeLeading(getEggData, startGetEggData)
];
